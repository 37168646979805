import React from "react";
import "twin.macro";
import { SEO } from "../components";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";

const SpeechPerception = () => {
  return (
    <>
      <SEO title="Speech Perception" />
      <BlankPageNoImage
        header="Speech Perception"
        intro="A hearing test will provide information on which frequency tones can be heard at certain volumes, however, this can be difficult to convert and relate to real life scenarios."
        ariaLabel="Speech Perception"
      >
        <ProseWrapper>
          <div>
            <h4>Speech Perception Testing</h4>
            <p>
              Speech perception testing allows the audiologist to analyse which
              speech sounds are most effected. This information can be used
              after a hearing aid fitting as a tool to demonstrate and confirm
              that the hearing aid devices are giving a definite improvement in
              this crucial area.
            </p>
          </div>
        </ProseWrapper>
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default SpeechPerception;
